<template>
  <a-layout-content>
    <div class="nav">
      <a-breadcrumb>
        <a-breadcrumb-item>档案库</a-breadcrumb-item>
        <a-breadcrumb-item>货栈档案库</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="content-container">
      <common-search :fields="fields" @search="onSearch"></common-search>
      <div class="main-content">
        <a-button
          type="primary"
          icon="plus-circle"
          class="btn-reset"
          @click="onAddArchives"
        >
          新建
        </a-button>
        <a-button
          icon="download"
          class="btn-reset"
          @click="exportAsExcel"
          style="margin-left: 8px"
        >
          导出
        </a-button>
        <a-table
          :data-source="tblData"
          :columns="tblColumns"
          :pagination="pagination"
          :loading="loading"
          :row-key="
            (record, index) => {
              return index;
            }
          "
          @change="onPage"
        >
          <template slot="businessVenuName" slot-scope="text, record">
            <div>
              <div>{{ record.dealerName }}</div>
              <div class="dealer-type">
                {{ record.dealerType
                }}{{ record.storeType ? "(" + record.storeType + ")" : "" }}
              </div>
            </div>
          </template>
          <a-space slot="action" slot-scope="text, record">
            <a @click="onDetail(record)">查看</a>
            <a @click="onModify(record)">修改</a>
            <a-popconfirm
              v-if="record.isDeleteAllow"
              title="是否确认删除?"
              ok-text="确认"
              cancel-text="取消"
              @confirm="onDelete(record.distributorId)"
            >
              <a>删除</a>
            </a-popconfirm>
            <a
              @click="handleOpenModal(record)"
              v-if="record.isTypeAddAvailable"
            >
              增加机构类型
            </a>
          </a-space>
        </a-table>
      </div>
    </div>
    <a-modal
      title="增加机构类型"
      :visible="openModal"
      width="600px"
      @ok="handleOk"
      @cancel="closeModal"
    >
      <a-form :form="modalForm" @submit="handleOk">
        <a-form-item
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 12 }"
          label="机构类型"
        >
          <a-select
            placeholder="请选择"
            v-decorator="[
              'distributorTypes',
              { rules: [{ required: true, message: '请选择机构类型' }] }
            ]"
          >
            <a-select-option
              v-for="item in distributorType"
              :value="item.id"
              :key="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-layout-content>
</template>

<script>
import { pageDetailMixins } from "@/components/pageDetailMixin";
import { tsFormat } from "@/components/DateUtils";
import { loadArea } from "@/services/DealerService";
import {
  deleteArchives,
  exportArchivesList,
  fetchArchivesList,
  whetherCouldAddArchives
} from "@/services/Archives";

const tblColumns = [
  {
    title: "机构名称",
    dataIndex: "corporationName"
  },
  {
    title: "合作状态",
    dataIndex: "incorporateStatus"
  },
  {
    title: "签署状态",
    dataIndex: "contractStatus"
  },
  {
    title: "创建时间",
    dataIndex: "createTime"
  },
  {
    title: "所在地区",
    dataIndex: "executorFullDetailAreaName"
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" }
  }
];
export default {
  mixins: [pageDetailMixins],
  data() {
    return {
      fields: [
        {
          label: "机构名称",
          key: "corporationName",
          component: "j-input"
        },
        {
          label: "地区",
          key: "consigneeProvinceCode,consigneeCityCode,consigneeCountyCode",
          component: "j-cascader",
          fieldNames: {
            label: "areaName",
            value: "areaCode",
            children: "subAreas"
          },
          areaData: [],
          getAreaData: () => {
            return this.loadAreaData();
          }
        },
        {
          label: "合作状态",
          key: "incorporateStatus",
          component: "j-select",
          defaultValue: "",
          options: [
            {
              label: "全部",
              value: ""
            },
            {
              label: "正常",
              value: "正常"
            },
            {
              label: "已退出",
              value: "已退出"
            },
            {
              label: "暂停合作",
              value: "暂停合作"
            }
          ]
        },
        {
          label: "创建时间",
          key: "startTime,endTime",
          component: "j-range-picker",
          format: "YYYY-MM-DD"
        }
      ],
      form: {},
      tblData: [],
      tblColumns,
      pagination: {
        hideOnSinglePage: true
      },
      loading: false,
      addDistributorTypeId: "", // 选择新增的机构 id
      openModal: false,
      modalForm: this.$form.createForm(this),
      distributorType: [
        { id: "serviceCenter", name: "服务中心" },
        { id: "operationCenter", name: "运营中心" },
        { id: "endConsumerMarket", name: "渠道终端" }
      ]
    };
  },
  activated() {
    this.fetchList();
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    // 加载行政区数据
    async loadAreaData() {
      let areaData = [];
      const resp = await loadArea();
      if (resp.data.code === "SUCCESS") {
        areaData = resp.data.data;
      } else {
        this.$message.error(resp.data.errorMsg);
      }
      return areaData;
    },
    // 获取列表信息
    fetchList() {
      const params = {
        corporationName: this.form.corporationName
          ? this.form.corporationName
          : "",
        incorporateStatus: this.form.incorporateStatus
          ? this.form.incorporateStatus
          : "",
        registeredAreaCode: "",
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10,
        distributorType: "warehouse",
        createTimeStart: this.form.startTime ? this.form.startTime : "",
        createTimeEnd: this.form.endTime ? this.form.endTime : ""
      };
      if (this.form.consigneeProvinceCode) {
        params.registeredAreaCode = this.form.consigneeProvinceCode;
      }
      if (this.form.consigneeCityCode) {
        params.registeredAreaCode = this.form.consigneeCityCode;
      }
      if (this.form.consigneeCountyCode) {
        params.registeredAreaCode = this.form.consigneeCountyCode;
      }
      fetchArchivesList(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          const records = resp.data.data.records;
          const data = resp.data.data;
          const pagination = { ...this.pagination };
          pagination.current = data.pageNum;
          pagination.total = data.total;
          pagination.pageSize = data.pageSize;
          pagination.showTotal = function(total, range) {
            return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
          };
          this.pagination = pagination;
          if (records.length > 0) {
            records.forEach(item => {
              if (item.createTime) {
                item.createTime = tsFormat(item.createTime);
              } else {
                item.createTime = "--";
              }
              if (
                !item.executorFullDetailAreaName ||
                item.executorFullDetailAreaName === "null"
              ) {
                item.executorFullDetailAreaName = "--";
              }
            });
          }
          this.tblData = records;
        }
      });
    },
    // 搜索
    onSearch(form) {
      this.form = form;
      this.pagination.current = 1;
      this.fetchList();
    },
    // 前往新建页面
    onAddArchives() {
      this.$router.push({
        name: "AddArchives",
        params: {
          type: "货栈",
          action: "新建"
        }
      });
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.fetchList();
    },
    // 查看档案详情
    onDetail(record) {
      this.$router.push({
        name: "CheckArchives",
        params: {
          type: "货栈",
          action: "查看",
          id: record.corporationId
        },
        query: {
          distributorId: record.distributorId
        }
      });
    },
    // 修改档案
    onModify(record) {
      this.$router.push({
        name: "ModifyArchives",
        params: {
          type: "货栈",
          action: "修改",
          id: record.corporationId
        },
        query: {
          distributorId: record.distributorId
        }
      });
    },
    // 删除
    onDelete(id) {
      deleteArchives(id).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.$message.success("删除成功");
          this.fetchList();
        } else {
          this.$message.error(resp.data.errorMsg);
        }
      });
    },
    // 导出
    exportAsExcel() {
      const params = {
        corporationName: this.form.corporationName
          ? this.form.corporationName
          : "",
        incorporateStatus: this.form.incorporateStatus
          ? this.form.incorporateStatus
          : "",
        registeredAreaCode: "",
        distributorType: "warehouse",
        createTimeStart: this.form.startTime ? this.form.startTime : "",
        createTimeEnd: this.form.endTime ? this.form.endTime : ""
      };
      if (this.form.consigneeProvinceCode) {
        params.registeredAreaCode = this.form.consigneeProvinceCode;
      }
      if (this.form.consigneeCityCode) {
        params.registeredAreaCode = this.form.consigneeCityCode;
      }
      if (this.form.consigneeCountyCode) {
        params.registeredAreaCode = this.form.consigneeCountyCode;
      }
      exportArchivesList(params).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fURL = document.createElement("a");
        fURL.href = fileURL;
        fURL.setAttribute("download", "货栈档案.xlsx");
        document.body.appendChild(fURL);

        fURL.click();
      });
    },
    // 打开弹窗
    handleOpenModal(record) {
      this.addDistributorTypeId = record.distributorId;
      this.openModal = true;
    },
    // 弹窗增加机构类型表单cancel
    closeModal() {
      this.modalForm.resetFields();
      this.openModal = false;
    },
    // 弹窗增加机构类型表单提交
    handleOk() {
      this.modalForm.validateFields(async (err, values) => {
        if (!err) {
          const { data } = await whetherCouldAddArchives(
            this.addDistributorTypeId,
            values.distributorTypes
          );
          if (!data.data) {
            this.$message.error("该机构类型已存在,不可重复创建");
            return;
          }
          // 跳转到详情页
          if (values.distributorTypes === "serviceCenter") {
            this.$router.push({
              name: "AddArchives",
              params: {
                type: "服务中心",
                action: "新建"
              },
              query: {
                corporationId: this.addDistributorTypeId,
                currentType: "warehouse"
              }
            });
          } else if (values.distributorTypes === "operationCenter") {
            this.$router.push({
              name: "AddArchives",
              params: {
                type: "运营中心",
                action: "新建"
              },
              query: {
                corporationId: this.addDistributorTypeId,
                currentType: "warehouse"
              }
            });
          } else if (values.distributorTypes === "endConsumerMarket") {
            this.$router.push({
              name: "AddArchives",
              params: {
                type: "渠道终端",
                action: "新建"
              },
              query: {
                corporationId: this.addDistributorTypeId,
                currentType: "warehouse"
              }
            });
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.content-container {
  min-height: 1000px;
  background-color: white;
  margin: 30px;
}

/deep/ .advanced-search-form .ant-form-item {
  display: flex;
}

/deep/ .ant-form-item .ant-form-item-control-wrapper {
  flex: 1;
}

/deep/ .ant-form-item .ant-form-item-label {
  text-align: right;
  min-width: 100px;
}

.row {
  display: flex;
}

.col {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.btn-reset {
  margin-bottom: 8px;
}

.dealer-type {
  color: rgba(153, 153, 153, 1);
  font-size: 12px;
}

.search__btn {
  margin-left: 58px;
}

.search__btn button {
  margin-right: 8px;
}
</style>
